window.addEventListener('heyflow-button-click', function(event) {
  const fields = event.detail.fieldsSimple;
  const eventName = event.detail.customEventName
  const phoneNumber = document.querySelector('.phone-input').value;

  if (eventName=="twilio_send_otp_button") {
    const formattedPhone = phoneNumber.replace(/\s+/g, '');
    const serviceSid = 'VA0ea1d8bf29842e8a94a6926b64e5ae08';
    const accountSid = 'AC04f9927bf7c75cf5f038dfb6aae12877';
    const authToken = '6af6abe20447ba024dcb3c3b8deb40d2';

    const data = new URLSearchParams();
    data.append('To', formattedPhone);
    data.append('Channel', 'sms');

    fetch(`https://verify.twilio.com/v2/Services/${serviceSid}/Verifications`, {
      method: 'POST',
      headers: {
        'Authorization': 'Basic ' + btoa(`${accountSid}:${authToken}`),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: data
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'pending') {
        alert('Kod weryfikacyjny został wysłany na ' + formattedPhone);
        const currentUrl = new URL(window.location.href);
        const params = currentUrl.searchParams;
        params.set("phoneNumber", encodeURIComponent(phoneNumber));
        const updatedUrl = `${currentUrl.origin}${currentUrl.pathname}?${params.toString()}`;
        history.pushState(null, '', updatedUrl);
      } else {
        alert('Wystąpił problem podczas wysyłania kodu weryfikacyjnego.');
      }
    })
    .catch(error => {
      console.error('Błąd:', error);
      alert('Wystąpił błąd podczas komunikacji z Twilio.');
    });
  } else {
    // alert('Proszę podać numer telefonu.');
  }
});

window.addEventListener('heyflow-button-click', function(event) {
  const otpInputElement = document.querySelector('[data-variable="otp-input"]').value;
  const currentUrl = new URL(window.location.href);
  const params = currentUrl.searchParams;
  const encodedPhoneNumber = params.get("phoneNumber");
  const eventName = event.detail.customEventName
  const submitButton = document.querySelector('[data-action="submit"]')
  const decodedPhoneNumber = encodedPhoneNumber ? decodeURIComponent(encodedPhoneNumber) : null;

  const formattedPhone = decodedPhoneNumber.replace(/\s+/g, '');
  const otp = otpInputElement.replace(/\s+/g, '');

  const serviceSid = 'VA0ea1d8bf29842e8a94a6926b64e5ae08';
  const accountSid = 'AC04f9927bf7c75cf5f038dfb6aae12877';
  const authToken = '6af6abe20447ba024dcb3c3b8deb40d2';

    if(eventName=="twilio_verify_otp_button"){
      const data = new URLSearchParams();
      data.append('To', formattedPhone);
      data.append('Code', otp);
    
      fetch(`https://verify.twilio.com/v2/Services/${serviceSid}/VerificationCheck`, {
        method: 'POST',
        headers: {
          'Authorization': 'Basic ' + btoa(`${accountSid}:${authToken}`),
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: data
      })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'approved') {
          submitButton.click()
        } else {
          alert('Kod weryfikacyjny jest nieprawidłowy.');
        }
      })
      .catch(error => {
        console.error('Błąd:', error);
        alert('Wystąpił błąd podczas komunikacji z Twilio.');
      });
    }
});
